import React from 'react';

import { ProductBox } from '../components/boxen';
import Seo from '../components/seo';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


const AboutPage = () => (
  <><Seo title="About" description="About Werner Digital Technology, Inc"/>
    <Paper sx={{padding: '0.5em 0.4em 1em'}}>

    <Typography variant='h4' component='h1'>
      About Us
    </Typography>

    Werner Digital Technology, Inc has been committed to providing
    quality solutions to our clients in northern Indianapolis since 2002.
    We specialize in cost-effective, reliable, and maintainable technology to enhance your current systems
    and procedures.

    <Typography mt={2} variant='h5' component='h2'>
      For more information:
    </Typography>
    <Box display='flex' flexWrap='wrap' justifyContent='center'>
      <ProductBox maintitle='Contact'
        to="/contact"
      >
        Questions?
      </ProductBox>
      <ProductBox maintitle='Privacy Policy'
        to="/privacy" linktitle='view'
      >
        We respect your privacy. Our full statement is available here,
      </ProductBox>
      <ProductBox maintitle='Terms of Service'
        to="/terms"
      >
        Terms of service for our software and hosted products.
      </ProductBox>
    </Box>

    </Paper>
  </>
)

export default AboutPage
